import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

function FormInput({ label, name, ...rest }) {
  return (
    <ColorTextField label={label} name={name} variant='outlined' {...rest} />
  );
}

export default FormInput;

const ColorTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: green[700],
    },
    '& .MuiInputLabel-root': {
      color: 'lightgrey',
      letterSpacing: '1.5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: green[700],
        borderWidth: 1,
      },
    },
    textTransform: 'capitalize',
    letterSpacing: '1.5px',
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))(TextField);
