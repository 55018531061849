import React, { useEffect } from 'react';
import '../styles/dashboard.css';
import { startViewer, stopViewer } from '../webrtc/viewer';

function Dashboard({ authObj, credentials }) {
  useEffect(() => {
    credentials &&
      startViewer(
        document.getElementsByClassName('remote-view')[0],
        credentials
      );
    return () => stopViewer();
  }, [credentials]);

  return (
    <div className='dashboard-screen'>
      heres dash
      {/* <div>{JSON.stringify(authObj)}</div> */}
      <div className='video-container'>
        <video className='remote-view' autoPlay playsInline controls />
      </div>
    </div>
  );
}

export default Dashboard;
