import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

function FormButton({ ...rest }) {
  return <ColorButton variant='outlined' {...rest} />;
}

export default FormButton;

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#282c34', //theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    fontWeight: '600',
    height: 56,
    fontSize: 20,
    letterSpacing: '4px',
    fontFamily: [
      'Montserrat',
      'Poppins',
      'Urbanist',
      'Courier New',
      'Arial',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '-apple-system',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))(Button);
