import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import { Auth } from 'aws-amplify';

function LoginScreen({ authObj, setAuthObj }) {
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [step, setStep] = useState('login');
  const [error, setError] = useState('');

  useEffect(() => {
    Auth.signOut({ global: true });
  }, []);

  const handleLogin = async (e) => {
    setError('');
    e.preventDefault();
    try {
      const response = await Auth.signIn(username, pass);
      setAuthObj(response);
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setStep('changePass');
      }
    } catch (error) {
      error.code && setError('Invalid credentials.');
      console.error('error signing in', error);
    }
  };
  const handlePassword = async (e) => {
    e.preventDefault();
    if (pass1 !== pass2) {
      setError('Passwords do not match.');
      return;
    }
    setStep('changePass');
    try {
      const response = await Auth.completeNewPassword(authObj, pass1);
      setAuthObj(response);
    } catch (error) {
      console.error('error completing new password', error);
    }
  };

  const forms = {
    login: {
      render: () => (
        <form className='login-form' onSubmit={handleLogin}>
          <FormInput
            style={{ margin: '4px 0' }}
            type='text'
            label='user'
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <FormInput
            style={{ margin: '4px 0' }}
            type='password'
            label='Pass'
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
          <FormButton style={{ margin: '4px 0' }} size='large' type='submit'>
            Go
          </FormButton>
        </form>
      ),
      message: 'Please authenticate.',
    },
    changePass: {
      render: () => (
        <form className='login-form' onSubmit={handlePassword}>
          <FormInput
            style={{ margin: '4px 0' }}
            type='password'
            label='Pass'
            value={pass1}
            onChange={(e) => setPass1(e.target.value)}
          />
          <FormInput
            style={{ margin: '4px 0' }}
            type='password'
            label='Confirm Pass'
            value={pass2}
            onChange={(e) => setPass2(e.target.value)}
          />
          <FormButton style={{ margin: '4px 0' }} size='large' type='submit'>
            Go
          </FormButton>
        </form>
      ),
      message: 'Please change your password.',
    },
  };
  if (!!authObj) {
    return <Redirect to='dash' />;
  }

  return (
    <div className='login-screen'>
      <h1 className='login-header'>blanconegroverde</h1>
      {error ? (
        <p className='login-subheader login-error'>{error}</p>
      ) : (
        <p className='login-subheader'>{forms[step].message}</p>
      )}
      {forms[step].render()}
    </div>
  );
}

export default LoginScreen;
