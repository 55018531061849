import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import Dashboard from './screens/Dashboard';
import { Auth } from 'aws-amplify';

function App() {
  const [authObj, setAuthObj] = useState();
  const [credentials, setCredentials] = useState();

  useEffect(() => {
    authObj && getCredentials();
  }, [authObj]);

  const getCredentials = async () => {
    try {
      const response = await Auth.currentCredentials();
      setCredentials(response);
    } catch (e) {
      console.error('error getting credentials', e);
    }
  };

  // if (!token) {
  //   return <LoginScreen setToken={setToken} token={token} />;
  // }

  return (
    <BrowserRouter>
      <Switch>
        {!authObj ? (
          <>
            <Route
              path='/login'
              render={() => (
                <LoginScreen setAuthObj={setAuthObj} authObj={authObj} />
              )}
            />
            <Redirect to='/login' />
          </>
        ) : (
          <>
            <Route
              path='/dash'
              render={() => (
                <Dashboard authObj={authObj} credentials={credentials} />
              )}
            />
            <Redirect to='/dash' />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
